<template>
  <div class="q-pa-md" style="text-align: right">
    <q-btn rounded bordered style="border-color: #ff7500" @click="showSupportDialog = true">
      <img src="@/assets/Short_logo.png" alt="Logo" style="height: 22px" />
      <div class="text-primary">Get help in developing this EGP</div>
    </q-btn>
  </div>

  <q-dialog v-model="showSupportDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">Let us support you!</div>
        <p>Please briefly describe your request and we will get back to you immediately!</p>
      </q-card-section>

      <q-card-section>
        <q-input v-model="supportForm.sender" label="Sender" />
        <q-input v-model="supportForm.message" label="Message" type="textarea" />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn flat label="Send Request" color="primary" @click="sendSupportRequest" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showFeedbackDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ feedbackMessage }}</div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'

const showSupportDialog = ref(false)
const showFeedbackDialog = ref(false)
const feedbackMessage = ref('')
const supportForm = ref({
  sender: '',
  header: 'Help with Questions',
  message: ''
})

const sendSupportRequest = async () => {
  try {
    await axios.post('common/sendMail', supportForm.value);
    feedbackMessage.value = 'Your support request has been sent. We will get back to you shortly.';
  } catch (error) {
    feedbackMessage.value = 'There was a problem sending your request. Please try again later';
  } finally {
    showSupportDialog.value = false;
    showFeedbackDialog.value = true;
  }
}
</script>

<style scoped>
.text-primary {
  color: #ff7500;
}
</style>