<template>
  <q-drawer class="bg-accent" v-model="leftDrawerOpen" show-if-above bordered>
    <q-list>
      <q-item-label header></q-item-label>
      <q-item clickable v-ripple @click="goToOverview" class="menu-item">
        <q-item-section avatar>
          <q-icon color="primary" name="home" />
        </q-item-section>
        <q-item-section class="menu-text">
          <div class="text-primary" style="menu-item">Home</div>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="goToNewEgp" class="menu-item">
        <q-item-section avatar>
          <q-icon color="primary" name="add_chart" />
        </q-item-section>
        <q-item-section class="menu-text">
          <div class="text-primary" style="menu-item">Generate new EGP</div>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="goToUpdateEgp" class="menu-item">
        <q-item-section avatar>
          <q-icon color="primary" name="update" />
        </q-item-section>
        <q-item-section class="menu-text">
          <div class="text-primary" style="menu-item">Manage EGPs</div>
        </q-item-section>
      </q-item>
    </q-list>
    <div class="q-pa-md fixed-bottom-left">
      <q-btn dense rounded class="" @click="showSupportDialog = true">
        <span class="material-icons">help</span>
        <div class="q-pa-md text-primary">Technical support</div>
      </q-btn>
    </div>
  </q-drawer>

  <q-dialog v-model="showSupportDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">We are sorry you are having difficulties.</div>
        <p>Please briefly describe your problem and we will get back to you immediately with a solution. Thank you!</p>
      </q-card-section>

      <q-card-section>
        <q-input v-model="supportForm.sender" label="Sender" />
        <q-input v-model="supportForm.message" label="Message" type="textarea" />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn flat label="Send Request" color="primary" @click="sendSupportRequest" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showFeedbackDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ feedbackMessage }}</div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.menu-item {
  transition:
    background-color 0.3s,
    border-left-color 0.3s;
  border-left: 3px solid transparent;
  padding-left: 12px;
  font-size: 18px;
}

.menu-item:hover {
  background-color: var(--q-color-primary);
  border-left-color: #ff7500;
}

.menu-icon {
  display: none;
  /* Den Icon-Teil standardmäßig ausblenden */
}

.menu-text {
  color: black;
}

.q-drawer[dir='rtl'] .menu-icon {
  display: block;
}

.material-icons {
  font-size: 30px;
  color: #ff7500;
}
</style>

<script setup>
import { ref, computed } from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store.js'

const leftDrawerOpen = computed(() => store.state.leftDrawerOpen)
const showSupportDialog = ref(false)
const showFeedbackDialog = ref(false)
const feedbackMessage = ref('')
const supportForm = ref({
  sender: '',
  header:'Technical support',
  message: ''
})

const goToOverview = async () => {
  router.push('/')
}

const goToNewEgp = async () => {
  router.push('/newEgp')
}

const goToUpdateEgp = async () => {
  router.push('/updateEgp')
}

const sendSupportRequest = async () => {
  try {
    await axios.post('common/sendMail', supportForm.value);
    feedbackMessage.value = 'Your support request has been sent. We will get back to you shortly.';
  } catch (error) {
    feedbackMessage.value = 'There was a problem sending your request. Please try again later';
  } finally {
    showSupportDialog.value = false;
    showFeedbackDialog.value = true;
  }
}
</script>