<template>
  <div v-if="question" class="question-card">
    <QuestionHeader :category="question.category" />
    <QuestionTitle
      :category="question.category"
      :label="`${question.lfdNr}: ${question.question}`"
    />
    <GipamCheckboxQuestionCard
      v-if="question.id === 14 || question.id === 15 || question.id === 21"
      :question="question"
      :componentName="componentName"
      @answer-change="handleAnswerChange"
      @show-template-dialog="showTemplateDialog"
    />
    <GipamRadioQuestionCard
      v-else
      :question="question"
      :componentName="componentName"
      @answer-change="handleAnswerChange"
      @show-template-dialog="showTemplateDialog"
    />

    <div class="button-container q-px-xl q-pb-sm">
      <q-btn
        color="primary"
        label="Previous Question"
        @click="previousQuestion(selectedAnswer)"
      />
      <q-btn
        v-if="question.id !== 25 && !isLastSelfCheckQuestion"
        color="primary"
        label="Save and Go to next Question"
        @click="nextQuestion(selectedAnswer)"
      />
      <q-btn
        v-if="question.id === 25"
        color="primary"
        label="Save and finish Questionnaire"
        @click="nextQuestion(selectedAnswer)"
      />
      <q-btn
        v-if="isLastSelfCheckQuestion"
        color="primary"
        label="Save and Return to Self-Check"
        @click="nextQuestion(selectedAnswer)"
      />
    </div>
  </div>
  <div v-else class="question-card">
    <div class="text-h3 text-primary">No question found</div>
  </div>
</template>

<script>

import GipamCheckboxQuestionCard from '../GipamCheckboxQuestionCard/GipamCheckboxQuestionCard.vue'
import GipamRadioQuestionCard from '../GipamCheckboxQuestionCard/GipamRadioQuestionCard.vue'
import QuestionHeader from '../QuestionHeader.vue'
import QuestionTitle from '../QuestionTitle.vue'

export default {
  components: {
    GipamCheckboxQuestionCard,
    GipamRadioQuestionCard,
    QuestionHeader,
    QuestionTitle,
  },
  props: {
    question: {
      type: Object,
      required: false,
      default: () => null,
    },
    componentName: {
      type: String,
      required: false,
    },
    isLastSelfCheckQuestion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedAnswer: null,
      selectedAnswers: [],
    }
  },
  emits: ['next-question', 'previous-question', 'answer-change'],
  methods: {
    nextQuestion() {
      let selectedAnswer = this.selectedAnswer
      if (
        !selectedAnswer &&
        this.question.answers.find((answer) => answer.isSelected)
      ) {
        selectedAnswer = this.question.answers.find(
          (answer) => answer.isSelected,
        ).id
      }
      this.$emit('next-question', selectedAnswer)
    },

    async handleTemplateCancel() {
      this.templateDialog = false
    },
    previousQuestion(selectedAnswer) {
      this.$emit('previous-question', selectedAnswer)
    },
    handleAnswerChange(answerIds) {
      this.selectedAnswer = answerIds
      this.$emit('answer-change', answerIds)
    },
    showTemplateDialog(answerId) {
      this.$emit('show-template-dialog', answerId)
    },
  },
}
</script>

<style scoped>
@import './GipamQuestionCardStyle.css';
</style>
