<template>
  <GipamTable
    :tableTitle="'Planning of cost-effectivness model(s)'"
    :columns="dynamicCells"
    :rowTypes="rowTypes"
    :rows="rows"
    :rowsPerPage="5"
    :dynamicOptions="dynamicOptions"
    :isMandatory="isMandatory"
    @update-value="updateValue"
    @option-selected="handleOptionSelected"
    @update-options="handleOptionsUpdate"
    @delete-clicked="deleteRow"
    ><template v-slot:title-action>
      <q-btn color="primary" label="Add Row" @click="newLine" class="q-ml-md" />
    </template>
  </GipamTable>
  <ErrorDialog
    v-model="errorDialog"
    :message="errorMessage"
    @close-dialog="closeErrorDialog"
  />
  <div class="column">
    <div class="col"></div>
    <div class="col self-end">
      <q-btn color="primary" class="q-mt-md q-mr-sm" @click="this.save"
        >Save</q-btn
      >
      <q-btn color="primary" class="q-mt-md" @click="this.cancel">Cancel</q-btn>
    </div>
  </div>
</template>
<script>
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamMandatoryErrorDialogView.vue'
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import { getNextStudyId } from '@/service/Api.ts'
import store from '@/store.js'
import axios from 'axios'
import { cloneDeep } from 'lodash'
export default {
  components: {
    GipamTable,
    ErrorDialog,
  },
  name: 'Template_24',
  emits: ['save-event', 'cancelEvent'],
  props: {
    msg: String,
  },
  data() {
    return {
      errorDialog: false,
      errorMessage:
        'Study ID, Project name / Internal ID, Main study objectives, Has this study already been started',
      nextStudyId: '1',
      dynamicOptions: [],
      columns: [
        {
          name: 'delete',
          align: 'left',
          label: '',
          field: 'delete',
          type: 'delete',
        },
        {
          name: 'studyId',
          label: 'Study Id',
          field: 'studyId',
          align: 'left',
          style: 'width: 350px',
          type: 'static',
          isMandatory: true,
        },
        {
          name: 'trialName',
          align: 'left',
          label: 'Project name / Internal ID',
          field: 'trialName',
          type: 'editable',
          isMandatory: true,
        },
        {
          name: 'studyObjectives',
          align: 'left',
          label: 'Main study objectives',
          field: 'studyObjectives',
          type: 'editableDropdown',
          isMandatory: true,
          options: [
            {
              label: 'Health economic core model',
              value: 'Health economic core model',
            },
            {
              label: 'Full cost-effectiveness analysis',
              value: 'Full cost-effectiveness analysis',
            },
            { label: 'Other: ', value: 'Other:' },
          ],
        },
        {
          name: 'targetCountries',
          align: 'left',
          label: 'Country/Countries',
          field: 'targetCountries',
          type: 'multipleSelectionDropdown',
          options: this.countryOptions,
        },
        {
          name: 'referenceModel',
          align: 'left',
          label: 'Do reference model exist?',
          field: 'referenceModel',
          type: 'dropdown',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'modelIfYes',
          align: 'left',
          label:
            'What could be the reference model? (refer to prior HTA appraisals)',
          field: 'modelIfYes',
          type: 'editable',
        },
        {
          name: 'literatureReview',
          align: 'left',
          label:
            'Should literature review be included in the study, to identify all relevant attributes based on a sound scientific methodology?',
          field: 'literatureReview',
          type: 'dropdown',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'endpointExploration',
          align: 'left',
          label:
            'Should an endpoint extrapolation be included in this project? ',
          field: 'endpointExploration',
          type: 'dropdown',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'utilityValues',
          align: 'left',
          label:
            'Are there existing utility values for each relevant health state?',
          field: 'utilityValues',
          type: 'dropdown',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'startDate',
          align: 'left',
          label: 'Intended start date',
          field: 'startDate',
          type: 'monthYear',
        },
        {
          name: 'finalizationDate',
          align: 'left',
          label: 'Intended finalization date',
          field: 'finalizationDate',
          type: 'monthYear',
        },
        {
          name: 'studyStarted',
          align: 'left',
          label: 'Has this study already been started?',
          field: 'studyStarted',
          type: 'dropdown',
          isMandatory: true,
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'finalization',
          align: 'left',
          label:
            'Do other study(ies) have to be completed before this study can begin?',
          field: 'finalization',
          type: 'dropdown',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'finalizationStudyNumber',
          align: 'left',
          label: ' Which study must be completed first?',
          field: 'finalizationStudyNumber',
          type: 'editableMultipleSelectionDropdown',
        },
        {
          name: 'comments',
          align: 'left',
          label: 'Comments/other specifics',
          field: 'comments',
          type: 'editable',
        },
      ],
      rows: [],
    }
  },
  computed: {
    dynamicCells() {
      const updatedColumns = [...this.columns]

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        const finalizationStudyNumberColumn = updatedColumns.find(
          (col) => col.name === 'finalizationStudyNumber',
        )

        const modelIfYesColumn = updatedColumns.find(
          (col) => col.name === 'modelIfYes',
        )

        if (modelIfYesColumn) {
          const hasFinalizationYes = row.referenceModel === 'Yes'

          if (hasFinalizationYes) {
            this.rowTypes[rowIndex]['modelIfYes'] = 'editable'
            // Reset finalizationStudyNumber value if studyDesign changes from 'Single-arm trial without controls'
            if (this.rows[rowIndex]['modelIfYes'] === 'N/A') {
              this.rows[rowIndex]['modelIfYes'] = null
            }
          } else {
            this.rows[rowIndex]['modelIfYes'] = 'N/A'
            this.rowTypes[rowIndex]['modelIfYes'] = 'static'
          }
        }

        if (finalizationStudyNumberColumn) {
          const hasFinalizationYes = row.finalization === 'Yes'

          if (hasFinalizationYes) {
            this.rowTypes[rowIndex]['finalizationStudyNumber'] =
              'editableMultipleSelectionDropdown'
          } else {
            this.rows[rowIndex]['finalizationStudyNumber'] = 'N/A'
            this.rowTypes[rowIndex]['finalizationStudyNumber'] = 'static'
          }
        }
      })

      return updatedColumns
    },
    rowTypes() {
      // Create an empty object to store the rowTypes
      const rowTypesObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        rowTypesObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          switch (column.type) {
            case 'editable':
              rowTypesObj[rowIndex][column.field] = 'editable'
              break
            case 'editableDropdown':
              rowTypesObj[rowIndex][column.field] = 'editableDropdown'
              break
            case 'monthYear':
              rowTypesObj[rowIndex][column.field] = 'monthYear'
              break
            case 'delete':
              rowTypesObj[rowIndex][column.field] = 'delete'
              break
            case 'dropdown':
              rowTypesObj[rowIndex][column.field] = 'dropdown'
              break
            case 'editableMultipleSelectionDropdown':
              rowTypesObj[rowIndex][column.field] =
                'editableMultipleSelectionDropdown'
              break
            case 'multipleSelectionDropdown':
              rowTypesObj[rowIndex][column.field] = 'multipleSelectionDropdown'
              break
            case 'static':
              rowTypesObj[rowIndex][column.field] = 'static'
              break
            // Add more cases if needed
            default:
              rowTypesObj[rowIndex][column.field] = 'static'
          }
        })
      })
      return rowTypesObj
    },
    isMandatory() {
      // Create an empty object to store the rowTypes
      const isMandatoryObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        isMandatoryObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          if (column.isMandatory) {
            isMandatoryObj[rowIndex][column.field] = true
          } else {
            isMandatoryObj[rowIndex][column.field] = false
          }
        })
      })
      return isMandatoryObj
    },
  },
  methods: {
    async newLine() {
      const newRow = {}
      this.columns.forEach((column) => {
        if (column.name !== 'delete') {
          newRow[column.name] = null
        }
      })
      newRow['studyId'] = this.nextStudyId
      this.rows.push(newRow)
      this.nextStudyId++
    },
    async deleteRow(rowIndex) {
      if (rowIndex !== -1) {
        this.rows.splice(rowIndex, 1)
      }
    },
    updateValue(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    handleOptionSelected(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    handleOptionsUpdate(updatedOptions, rowIndex, columnIndex) {
      const columnName = this.columns[columnIndex].name
      const updatedOptionsForColumn = {
        [columnName]: updatedOptions,
      }

      // Update the dynamicOptions array with the updated options
      this.dynamicOptions.splice(rowIndex, 1, updatedOptionsForColumn)

      // You might also want to update the options in the corresponding column
      const correspondingColumn = this.columns.find(
        (col) => col.name === columnName,
      )
      if (correspondingColumn) {
        correspondingColumn.options = updatedOptions
      }
    },
    save() {
      const rowsWithEmptyFields = this.rows.filter((row) => {
        return (
          !row.studyId ||
          !row.trialName ||
          !row.studyObjectives ||
          !row.studyStarted ||
          row.studyStarted.length === 0
        )
      })

      if (rowsWithEmptyFields.length > 0) {
        console.log(
          'Error: Study ID, Standard of Care, and Specify Outcome are mandatory for all rows',
        )
        // Show an error message or handle the validation error
        this.errorDialog = true
        return
      }
      let data = []
      this.rows.forEach((element) => {
        data.push(element)
      })

      this.$emit('save-event', data)
    },
    cancel() {
      this.$emit('cancel-event')
    },
    closeErrorDialog() {
      this.errorDialog = false
    },
  },
  async mounted() {
    let templateId = store.getters.getCurrentTemplateId
    let egpId = store.getters.getCurrentEgpId
    this.nextStudyId = await getNextStudyId(egpId)
    // Initialize dynamicOptions with the same options as columns
    this.dynamicOptions = this.columns.map((col) => ({
      [col.name]: col.options || [],
    }))

    if (templateId && egpId) {
      let responseTemplate = await axios.get(
        '/template/getTemplateAntworten/' + egpId + '/' + templateId,
      )

      const loadCountryOptionsResult = await axios.get(
        '/egp/loadCountryOptions/',
      )
      const finalizationStudyNumberResult = await axios.get(
        '/template/finalizationStudyNumber/' + egpId,
      )
      const newColumns = cloneDeep(this.columns)
      const targetCountriesColumn = newColumns.find(
        (col) => col.name === 'targetCountries',
      )
      const finalizationStudyNumberColumn = newColumns.find(
        (col) => col.name === 'finalizationStudyNumber',
      )
      if (
        !Array.isArray(finalizationStudyNumberResult.data) ||
        finalizationStudyNumberResult.data.length === 0
      ) {
        finalizationStudyNumberColumn.options = []
      } else {
        // Map each entry in the server response to an option object
        finalizationStudyNumberColumn.options =
          finalizationStudyNumberResult.data.map((entry) => ({
            label: entry,
            value: entry,
          }))
        finalizationStudyNumberColumn.options.push({
          label: 'Other: ',
          value: 'Other:',
        })
      }

      // Handle empty or invalid responses
      if (
        !Array.isArray(loadCountryOptionsResult.data) ||
        loadCountryOptionsResult.data.length === 0
      ) {
        targetCountriesColumn.options = []
      } else {
        // Map each entry in the server response to an option object
        targetCountriesColumn.options = loadCountryOptionsResult.data.map(
          (entry) => ({
            label: entry.en,
            value: entry.en,
          }),
        )
        targetCountriesColumn.options.unshift({
          label: 'Global',
          value: 'Global',
        })
      }
      // Assign the new columns array to this.columns
      this.columns = newColumns
      if (responseTemplate.data && typeof responseTemplate.data === 'object') {
        this.rows = Object.values(responseTemplate.data).map((row) => {
          const newRow = {}
          this.columns.forEach((column) => {
            if (column.name !== 'delete') {
              newRow[column.name] = row[column.name] || null
            }
          })
          return newRow
        })
      } else {
        this.rows = []
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
