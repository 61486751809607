<template>
  <q-page class="bg-accent">
    <div class="q-pa-md">
      <q-btn rounded color="primary" @click="onSupport">
        <img src="@/assets/Short_logo.png" alt="Logo" class="logo" />
        <div class="text-white">Get help in developing this EGP</div>
      </q-btn>
    </div>
    <div class="q-pa-lg d-flex justify-center">
      <q-card align="center" bordered flat class="basic-data bg-white">
        <q-card-section class="bg-primary">
          <div class="text-white text-h5">Basic data 1/2</div>
        </q-card-section>
        <q-card-section>
          <q-input ref="inputRef" class="q-pa-sm" outlined dense v-model="nameEgp" label="Name EGP"
            :rules="[(val) => !!val || 'Field is required']" />
          <q-input class="q-pa-sm" outlined dense v-model="asset" label="Asset"
            :rules="[(val) => !!val || 'Field is required']" />
        </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">
            <p class="left-align-header">
              Welcome to the EVIGATOR, the tool that will support you in
              developing and executing an Evidence Generation Plan (EGP). The
              software has currently the following modules:
            </p>
            <ul class="left-align">
              <li>
                Development of an EGP: About 30 questions will guide you through
                the development, the results will be a structured EGP for
                implementation or further discussions
                <a href="/EgpMonitoring?egpId=1800">(see example)</a>. You will
                need about 15-20 minutes to create a first draft.
              </li>
              <li>Pressure test and revision of an existing EGP</li>
              <li>
                Implementation of an EGP: Monitoring and reporting system for
                ongoing/completed research projects
              </li>
            </ul>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="Save and continue" @click="onSave" />
        </q-card-actions>
      </q-card>
    </div>
    <q-dialog v-model="showNameRequiredDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Please enter a name for your EGP</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="OK" @click="closeNameRequiredDialog" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Support Dialog -->
    <q-dialog v-model="showSupportDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Let us support you!</div>
          <p>Please briefly describe your request and we will get back to you immediately!
          </p>
        </q-card-section>

        <q-card-section>
          <q-input v-model="supportForm.sender" label="Sender" />
          <q-input v-model="supportForm.message" label="Message" type="textarea" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn flat label="Send Request" color="primary" @click="sendSupportRequest" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Feedback Dialog -->
    <q-dialog v-model="showFeedbackDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ feedbackMessage }}</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import router from '@/router'
import store from '@/store.js'
import axios from 'axios'
import { ref } from 'vue'

export default {
  name: 'newEgp',
  setup() {
    const inputRef = ref(null)
    return {
      nameEgp: ref(''),
      inputRef,
    }
  },
  data() {
    return {
      asset: '',
      tpp: null,
      selectedFileName: '',
      showNameRequiredDialog: false,
      showSupportDialog: false,
      showFeedbackDialog: false,
      feedbackMessage: '',
      supportForm: {
        sender: '',
        header: 'Help with new EGP',
        message: ''
      }
    }
  },
  computed: {
    isNameEgpValid() {
      return this.nameEgp.trim() !== ''
    },
    isAssetValid() {
      return this.asset.trim() !== ''
    },
  },
  methods: {
    async onSave() {
      if (!this.isNameEgpValid || !this.isAssetValid) {
        this.showNameRequiredDialog = true
        return
      }
      try {
        let newEpg = { id: -1, name: this.nameEgp, asset: this.asset }
        let responseSave = await axios.post('/egp/save', newEpg)

        store.commit('changeEgpId', responseSave.data)
        store.commit('changeFrageId', -1)
        router.push('/BasicData/')
      } catch (err) {
        console.log(err.response)
      }
    },
    closeNameRequiredDialog() {
      this.showNameRequiredDialog = false
    },
    openAttachmentDialog() {
      // Hier sollte sich der Datei-Explorer öffnen, um 1 oder mehrere Dateien auszuwählen
    },
    async onSupport() {
      this.showSupportDialog = true
    },
    async sendSupportRequest() {
      try {
        await axios.post('common/sendMail', this.supportForm);
        this.feedbackMessage = 'Your support request has been sent. We will get back to you shortly.';
      } catch (error) {
        this.feedbackMessage = 'There was a problem sending your request. Please try again later';
      } finally {
        this.showSupportDialog= false;
        this.showFeedbackDialog = true;
      }
    },
    openFileInput() {
      this.$refs.fileInput.click() // Öffnet das Dateiauswahldialogfeld
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile) {
        this.tpp = selectedFile // Speichert das ausgewählte Dateiobjekt
        this.selectedFileName = selectedFile.name // Speichert den Dateinamen
      } else {
        this.tpp = null // Null, um anzuzeigen, dass keine Datei ausgewählt wurde
        this.selectedFileName = '' // Leert den Dateinamen
      }
    },
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
  font-weight: normal;
}

.left-align-header {
  text-align: left;
}

.q-icon.cursor-pointer {
  font-size: 20px;
  margin-right: 8px;
  /* Einen Abstand zwischen dem Icon und dem Text hinzufügen */
}

.logo {
  height: 22px;
}

.text-h6 {
  font-size: 18px;
}

.q-input {
  margin-bottom: 10px;
}

.q-card {
  margin: 0 auto;
  max-width: 75%;
}

.q-card-section {
  padding: 10px;
}

.text-h5 {
  font-size: 20px;
}
</style>