<template>
  <q-page class="bg-accent">
    <div class="q-pa-md q-gutter-md">
      <div class="col justify-between">
        <q-parallax src="../assets/background_startseite.jpg" :height="350">
          <img src="../assets/evigator_logo.svg" alt="Logo" style="height: 150px" />
          <h1 class="welcome-text">Welcome!</h1>
        </q-parallax>
      </div>
    </div>
    <div class="q-pa-md items-start">
      <q-card class="my-card" flat bordered>
        <q-card-section>
          <div class="text-subtitle2">
            <p>
              Welcome to the EVIGATOR, the tool that will support you in developing and executing an Evidence Generation
              Plan (EGP). The software has currently the following modules:
            </p>
            <ul class="left-align">
              <li>
                Development of an EGP: About 30 questions will guide you through the development, the results will be a
                structured EGP for implementation or further discussions
                <a href="/EgpMonitoring?egpId=1800">(see example)</a>. You will need about 15-20 minutes to create a
                first draft.
              </li>
              <li>Self-check: Pressure test and revision of an existing EGP</li>
              <li>
                Implementation of an EGP: Monitoring and reporting system for ongoing/completed research projects
              </li>
            </ul>
          </div>
        </q-card-section>
      </q-card>
      <q-card class="my-card" flat bordered>
        <q-card-section class="q-pt-md">
          <div class="text-h5">Last Edited Items</div>
          <q-card-section>
            <q-item>
              <div>
                <strong>Name: </strong><span class="text-dark">{{ nameEgp }}</span>
              </div>
            </q-item>
            <q-item>
              <div>
                <strong>Asset: </strong><span class="text-dark">{{ asset }}</span>
              </div>
            </q-item>
            <q-item>
              <div>
                <strong>Last modified at: </strong><span class="text-dark">{{ modifiedAt }}</span>
              </div>
            </q-item>
          </q-card-section>
          <q-card-section class="col flex-right">
            <q-btn class="row" unelevated color="primary" label="Continue" @click="continueClicked" />
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
  </q-page>
</template>

<style>
.left-align {
  text-align: left;
  font-weight: normal;
}
.welcome-text {
  color: #ffffff;
  font-size: 5rem;
  text-align: center;
  margin-top: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.my-card {
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

</style>

<script>
import axios from 'axios'

export default {
  name: 'HomePage',
  data() {
    return {
      egpId: null,
      nameEgp: '',
      asset: '',
      modifiedAt: '',
    }
  },
  async mounted() {
    try {
      let controllerMethod = '/egp/lastEditedEgp'

      let response = await axios.get(controllerMethod)
      const dataItem = response.data[0]
      this.egpId = dataItem.id
      this.nameEgp = dataItem.name
      this.asset = dataItem.asset
      const modifiedDate = new Date(dataItem.modifiedAt)
      this.modifiedAt = modifiedDate.toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    } catch (err) {
      console.log(err.response)
    }
  },
  methods: {
    async continueClicked() {
      this.$router.push('/EditQuestions?egpId=' + this.egpId)
    },
  },
}
</script>