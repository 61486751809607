<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar class="bg-primary">
        <q-btn
          flat
          dense
          round
          @click="onLeftDrawerOpenClick()"
          aria-label="Menu"
          icon="menu"
          color="white"
        />

        <q-toolbar-title>
          <div class="text-white">Menu</div>
        </q-toolbar-title>

        <div>
          <q-btn rounded class="bg-white">
            <img
              src="@/assets/Short_logo.png"
              alt="Logo"
              style="height: 22px"
            />
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup @click="onLogout">
                  <q-item-section>Logout</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <AppMenu />

    <q-page-container>
      <router-view> </router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
//import { ref } from 'vue'
import AppMenu from '@/components/AppMenu.vue'
import router from '@/router.js'
import store from '@/store.js'

export default {
  name: 'AppLayout',

  components: {
    AppMenu,
  },

  setup() {
    function onLeftDrawerOpenClick() {
      store.commit('changeLeftDrawerOpen')
    }

    function onLogout() {
      store.commit('setLoggedOut')
      router.push('/login')
    }

    return {
      onLeftDrawerOpenClick,
      onLogout,
    }
  },
}
</script>
